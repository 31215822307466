ul li a.active {
  color: #835713 !important;
  background-color: white;
}
.link.active button {
  color: white !important;
  background-color: #835713;
  position: relative;
  z-index: 2;
}

